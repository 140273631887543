/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbar.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "nav", [["class", "navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "navbar-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "navbar-brand"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "button", [["class", "navbar-toggler"], ["mat-raised-button", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sidebarToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "navbar-toggler-icon icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "navbar-toggler-icon icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "navbar-toggler-icon icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "collapse navbar-collapse justify-content-end"], ["id", "navigation"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTitle(); _ck(_v, 4, 0, currVal_0); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i2.NavbarComponent, [i3.Location, i1.ElementRef, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i2.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
