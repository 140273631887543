import {Component, OnInit} from '@angular/core';
import {PlayerRole} from "../../services/player.service";
import {AuthService} from "../../services/auth.service";

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    roles: PlayerRole[];
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '', roles: [] },
  { path: '/series', title: 'Serien',  icon: 'dashboard', class: '', roles: [PlayerRole.ADMINISTRATOR, PlayerRole.MANAGER] },
  { path: '/player', title: 'Spieler',  icon: 'dashboard', class: '', roles: [PlayerRole.ADMINISTRATOR, PlayerRole.PLAYER, PlayerRole.MANAGER] },
  { path: '/list', title: 'Ranglisten',  icon: 'dashboard', class: '', roles: [PlayerRole.ADMINISTRATOR, PlayerRole.PLAYER, PlayerRole.MANAGER] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  userHasRole(roles: PlayerRole[]): boolean {
      return AuthService.userHasRole(roles);
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      return $(window).width() <= 991;
  };
  logout() {
      AuthService.signOut();
      window.location.reload(true);
  }
}
