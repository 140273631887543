import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';

import {SkatPlannerComponent} from './layouts/skat-planner/skat-planner.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: SkatPlannerComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/skat-planner/skat-planner.module#SkatPlannerModule'
      }]
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [],
})

export class AppRoutingModule {
}
