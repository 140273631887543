/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./sidebar.component";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_SidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["routerLinkActive", "active"]], [[8, "hidden", 0], [8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_2 = "active"; _ck(_v, 1, 0, currVal_2); var currVal_5 = _ck(_v, 6, 0, _v.context.$implicit.path); _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userHasRole(_v.context.$implicit.roles); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.class, " nav-item"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).target; var currVal_4 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.icon; _ck(_v, 8, 0, currVal_6); var currVal_7 = _v.context.$implicit.title; _ck(_v, 10, 0, currVal_7); }); }
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "simple-text"], ["href", "/dashboard"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hanseaten Bremen "])), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "sidebar-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 21, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "li", [["class", "nav-item"], ["style", "margin-bottom: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["list"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aktuelle Rangliste"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i1.ɵdid(16, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 10, "li", [["class", "nav-item"], ["routerLinkActive", "active"], ["style", "margin-top: 20px"]], null, null, null, null, null)), i1.ɵdid(18, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(21, 0, null, null, 6, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.logout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(23, 1), (_l()(), i1.ɵeld(24, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["exit_to_app"])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"])), (_l()(), i1.ɵeld(28, 0, null, null, 1, "span", [["style", "position: absolute; bottom: 0; left: 2px; font-size: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Version: dev (11.10.2021)"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMobileMenu(); _ck(_v, 5, 0, currVal_0); var currVal_3 = _ck(_v, 10, 0, "/list/5"); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.menuItems; _ck(_v, 16, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 18, 0, currVal_5); var currVal_8 = _ck(_v, 23, 0, "/login"); _ck(_v, 22, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).target; var currVal_2 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_6 = i1.ɵnov(_v, 22).target; var currVal_7 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_6, currVal_7); }); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i4.SidebarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i4.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
