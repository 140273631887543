<div class="wrapper">
    <div *ngIf="!fullScreen" class="sidebar" data-color="danger" data-background-color="white">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div [ngClass]="{ 'main-panel-full-width': fullScreen, 'main-panel': !fullScreen }">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>
    </div>
</div>
