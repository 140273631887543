<div class="logo">
    <a href="/dashboard" class="simple-text">
<!--        <div class="logo-img">-->
<!--            <img src="./assets/img/skat-icon.png"/>-->
<!--        </div>-->
        Hanseaten Bremen
    </a>
</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
  </div>
    <ul class="nav">

        <li style="margin-bottom: 20px" class="nav-item">
            <a class="nav-link" [routerLink]="['/list/5']">
                <i class="material-icons">list</i>
                <p>Aktuelle Rangliste</p>
            </a>
        </li>

        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" [hidden]="!userHasRole(menuItem.roles)" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>

        <li style="margin-top: 20px" routerLinkActive="active" class="nav-item">
            <a class="nav-link" [routerLink]="['/login']" (click)="logout()">
                <i class="material-icons">exit_to_app</i>
                <p>Logout</p>
            </a>
        </li>
    </ul>
    <span style="position: absolute; bottom: 0; left: 2px; font-size: 10px">Version: dev (11.10.2021)</span>
</div>
