import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Series} from "./series.service";

export enum PlayerStatus {
  ACTIVE,
  INACTIVE,
  GUEST,
}

export let PlayerStatusFriendly = [
  {status: "Aktiv", value: PlayerStatus.ACTIVE},
  {status: "Inaktiv", value: PlayerStatus.INACTIVE},
  {status: "Gast", value: PlayerStatus.GUEST},
];

export enum PlayerRole {
  ADMINISTRATOR = 0,
  ANONYMOUS = 1,
  PLAYER = 2,
  MANAGER = 3,
}

export class Player {
  id: number;
  forename: String;
  surname: String;
  address: String;
  postcode: String;
  city: String;
  phoneNumber: String;
  email: String;
  playerPassId: String;
  password: String;
  passwordSet: boolean;
  entry: Date;
  birthday: Date;
  status: PlayerStatus;
  role: PlayerRole;
  results: PlayerResult[];
}

export class PlayerResult {
  id: number;
  createdDate: Date;
  updatedDate: Date;
  gamesWon: number;
  gamesLost: number;
  gamePoints: number;
  player: Player;
  series: Series;
}

export class ComputedPlayerResult {
  position: number;
  gamesWon: number;
  gamesLost: number;
  gamePoints: number;
  allGamePoints: number;
  seriesPlayed: number;
  lastDayPoints: number;
  averagePoints: number;
  averagePointsWeighted: number;
  opponentGamePoints: number;
  player: Player;
}

@Injectable()
export class PlayerService {

  protected url : string = '/api/v1/player';

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Player[]>(this.url + '/all').pipe(map(data => data));
  }

  getNextBirthdays(amount: number) {
    return this.http.get<{forename: string, surname: string, birthday: Date, age: number}[]>(this.url + '/birthdays?amount='+amount).pipe(map(data => data));
  }

  update(player: Player) {
    return this.http.patch(this.url + "/" + player.id, player);
  }

  create(player: Player) {
    return this.http.put(this.url, player);
  }

  delete(player: Player) {
    return this.http.delete(this.url + "/" + player.id);
  }

  setPassword(key: string, password: string) {
    return this.http.post(this.url + "/setpassword", {key: key, password: password});
  }

  forgotPassword(email: string) {
    return this.http.post(this.url + "/forgotpass", {email: email});
  }
}
