import {HTTP_INTERCEPTORS, HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {throwError} from "rxjs";
import {Router} from "@angular/router";
import {Player, PlayerRole} from "./player.service";

const TOKEN_HEADER_KEY = 'x-access-token';

@Injectable()
export class AuthService {

  protected url : string = '/api/v1/auth';

  static TOKEN_KEY: string = "token";
  static PLAYER_KEY: string = "player";

  static playerLoggedIn: Player;
  static theToken: string;

  static saveSession(token: string, player: Player) {
    this.saveToken(token);
    this.savePlayer(player);
  }

  static clearSession() {
    this.playerLoggedIn = null;
    this.theToken = null;
    window.localStorage.clear();
  }

  static saveToken(token: string) {
    this.theToken = token;
    window.localStorage.removeItem(AuthService.TOKEN_KEY);
    window.localStorage.setItem(AuthService.TOKEN_KEY, token);
  }

  static getToken() {
    return this.theToken || (this.theToken = window.localStorage.getItem(AuthService.TOKEN_KEY));
  }

  static savePlayer(player: Player) {
    this.playerLoggedIn = player;
    window.localStorage.removeItem(AuthService.PLAYER_KEY);
    window.localStorage.setItem(AuthService.PLAYER_KEY, JSON.stringify(player));
  }

  static getPlayer(): Player {
    return this.playerLoggedIn || (this.playerLoggedIn = JSON.parse(window.localStorage.getItem(AuthService.PLAYER_KEY)));
  }

  static signOut() {
    AuthService.clearSession();
  }

  static checkSession(): boolean {
    return !!(this.getToken() && this.getPlayer());
  }

  static userHasRole(roles: PlayerRole[]): boolean {
    if(!roles || roles.length < 1) {
      return true;
    }
    if(!this.getPlayer()) {
      return false;
    }
    return roles.indexOf(this.getPlayer().role) !== -1;
  }

  constructor(private http: HttpClient) {}

  signIn(email: string, password: string) {
    let header = {headers:{'Content-Type': 'application/json'}};

    return this.http.post<any>(this.url + '/signIn', JSON.stringify({email: email, password: password}), header).pipe(map(data => data));
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authReq = req;
    const token = AuthService.getToken();

    if(token != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }
    return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
    }), catchError((response) => {
      if(response.status === 403) {
        AuthService.clearSession();
        this.router.navigate(['login']);
      }

      return throwError(response);
    }));
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
